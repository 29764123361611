import React, { useState } from 'react'
import { Column, Grid } from 'components/grid'
import Form from 'react-netlify-form'
import { Submit } from 'components/forms/elements/v2/submit'
import Email from 'components/forms/elements/email'
import Phone from 'components/forms/elements/phone'
import Input from 'components/forms/elements/input'
import Location from 'components/forms/elements/location'
import Textarea from 'components/forms/elements/textarea'
import ResponseMessage from 'components/forms/elements/response-message'
import { useFormValidation } from 'hooks/use-form-validation'
import Spacer from 'components/new/spacer'

const BulkOilForm = () => {
  const [valid, updateValidationSet] = useFormValidation({})
  const [started, setStarted] = useState(false)
  return (
    <Form name='Bulk Oil'>
      {({ loading, error, success }) => (
        <div>
          {success && (
            <ResponseMessage
              message='Success!'
              messageBody='Your form has been submitted. Our team will be in touch with you soon!'
            />
          )}
          {error && (
            <ResponseMessage
              error={true}
              message='Error Occurred.'
              messageBody='There was an issue submitting your form. If problems persist please contact <a href="mailto:contactus@hutsoninc.com">our support team</a>.'
            />
          )}
          <div onFocus={() => setStarted(true)} role='none'>
            <Grid>
              <Column columns='1/3'>
                <Input
                  updateValidationSet={updateValidationSet}
                  label='First Name'
                  name='First Name'
                  validationError='Please enter a valid first name.'
                  required
                />
              </Column>
              <Column columns='1/3'>
                <Input
                  updateValidationSet={updateValidationSet}
                  label='Last Name'
                  name='Last Name'
                  validationError='Please enter a valid last name.'
                  required
                />
              </Column>
              <Column columns='1/3'>
                <Input
                  updateValidationSet={updateValidationSet}
                  label='Business Name'
                  name='Business Name'
                  validationError='Please enter a valid business name.'
                />
              </Column>
            </Grid>
            <Grid>
              <Column columns='2/8'>
                <Phone updateValidationSet={updateValidationSet} required />
              </Column>
              <Column columns='3/8'>
                <Email updateValidationSet={updateValidationSet} required />
              </Column>
              <Column columns='3/8'>
                <Location
                  label='Nearest Hutson Location'
                  updateValidationSet={updateValidationSet}
                  required
                ></Location>
              </Column>
            </Grid>
            <Textarea
              updateValidationSet={updateValidationSet}
              label='Questions/comments'
              name='Comments'
              validationError='Please enter a question or comment.'
            />
            <Spacer size='s' />
            <Submit disabled={(!valid && started) || success} loading={loading} />
          </div>
        </div>
      )}
    </Form>
  )
}

export default BulkOilForm
