import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql, Link } from 'gatsby'
import Spacer from 'components/new/spacer'
import BulkOilForm from 'components/forms/bulk-oil'

const BulkOilPage = props => {
  const {
    data: { heroImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Bulk Oil | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Parts',
                'item': 'https://www.hutsoninc.com/parts/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Bulk Oil',
                'item': 'https://www.hutsoninc.com/parts/bulk-oil/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Bulk Oil'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>Oil you need, when you need it</H2>
        <P>
          We offer a delivery service to your farm for 250 and 500-gallon oil tanks for your
          convenience. Hygard Hydraulic or Plus 50 Engine Oil is also available for delivery with a
          250-gallon minimum. We’re proud to offer routine oil deliveries for our customers through
          our Bulk Oil Program. Enroll today to start receiving the oil you need, when you need it.
          Looking for more information or need to request an oil refill?{' '}
          <Link to='/locations/'>Contact your local Hutson store</Link> today. Our team will be
          happy to assist you.
        </P>
        <Spacer />
        <H2>Request bulk oil services</H2>
        <P>
          Ready to get started with bulk oil or need a refill? Get in touch with our team at your
          nearest Hutson location!
        </P>
        <Spacer size='s' />
        <BulkOilForm />
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "parts/bulk-oil-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default BulkOilPage
